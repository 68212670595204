import React, { useMemo } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { DROP_SVG } from "../../../constants/svgConstants";
import { useToggle } from "../../../hooks/useToggle";

function DropBlock({
  dropItems,
  activeId,
  changeActiveId,
  defaultTitle,
  dropRef,
  discoverClubRequired,
  hasError,
  errorTitle,
}) {
  const [showDrop, setShowDrop, off] = useToggle();
  const activeTitle = useMemo(() => {
    const elem = dropItems.find(({ id }) => +id == +activeId);
    if (!elem) return defaultTitle;

    return elem.title;
  }, [activeId, dropItems, defaultTitle]);

  return (
    <div className="club-rules__dropdown-block">
      <div
        ref={dropRef}
        className={clsx("club-rules__dropdown-title-block", {
          "club-rules__dropdown-title-block_active": showDrop,
          "club-rules__dropdown-block--required": discoverClubRequired && !showDrop,
        })}
        onClick={setShowDrop}
      >
        <span className="club-rules__dropdown-text">{activeTitle}</span>
        <span className="club-rules__dropdown-svg-block">{DROP_SVG}</span>
      </div>
      <div className="error-msg-wrapper">
        {hasError && <span className="error-msg">{errorTitle}</span>}
      </div>
      {showDrop && (
        <div className="club-rules__dropdown-list-wrapper">
          <div className="club-rules__dropdown-list">
            {dropItems.map(({ id, title }) => (
              <div
                key={id}
                className={`club-rules__dropdown-item ${
                  id === activeId ? "club-rules__dropdown-item_active" : ""
                }`}
                onClick={() => {
                  changeActiveId(id);
                  off();
                }}
              >
                {title}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

DropBlock.propTypes = {
  dropItems: PropTypes.array,
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changeActiveId: PropTypes.func,
  defaultTitle: PropTypes.string,
};
DropBlock.defaultProps = {
  dropItems: [],
  activeId: null,
  changeActiveId: () => {},
  defaultTitle: "",
};

export default DropBlock;
