export const trainingNameHandler = (count) =>
  `${
    count === 1
      ? "one_visit"
      : count >= 2 && count <= 4
      ? "few_visits"
      : "more_visits"
  }`;

export const freezeNameHandler = (count) =>
  `${
    count === 1
      ? "one_freezing"
      : count >= 2 && count <= 4
      ? "few_freezing"
      : "lot_freezing"
  }`;

export const paidNameHandler = (paid) => {
  switch (paid) {
    case -1:
      return "missing";
    case 2:
      return "liqpay";
    case 4:
      return "account";
    case 5:
      return "booking";
    case 10:
      return "portmone";
    case 11:
      return "wayForPay";

    default:
      return "account";
  }
};

export const BUY_BUTTON_TITLES = {
  buy: "buy",
  prolong: "prolong",
  activate: "activate",
  unfreeze: "unfreeze",
  freeze: "freeze",
  pay: "pay",
  // activated: 'activated',
  // expired: 'Время истекло',
};
