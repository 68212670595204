import React from "react";

import { HocWithPortal } from "../../../../../hocs/hocWithPortal";
import {
  NOSUCCESS_SVG,
  SUCCESS_SVG,
} from "../../../../../constants/svgConstants";
import { AlertBlock } from "../../../../common/AlertBlock";

const EnrollSubscription = ({ data, onClose, title, recount = () => null }) => {
  const { title: message, isSuccess } = data;

  return (
    <AlertBlock onClose={onClose} innerFull>
      <>
        {isSuccess && isSuccess !== "false" ? (
          <>
            {SUCCESS_SVG}
            <h2
              className="sign-up-section__title"
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "35px",
              }}
            >
              {title}
            </h2>
          </>
        ) : (
          <>
            {NOSUCCESS_SVG}
            <h2
              className="sign-up-section__title"
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "35px",
              }}
            >
              {message}
            </h2>
          </>
        )}
      </>
    </AlertBlock>
  );
};

export default HocWithPortal(EnrollSubscription);
