import React, { useCallback, memo, useState } from "react";

import ChooseProfileBlock from "../ChooseProfileBlock/ChooseProfileBlock";

import { CardButton } from "../../../../../common/CardButton/CardButton";
import { useToggle } from "../../../../../../hooks/useToggle";
import { useTranslation } from "next-i18next";

import { useAuth } from "../../../../../../utils/auth";
import { useFamilyData } from "../../../../../../utils/apollo/reactivities";

export const ChooseProfileSection = memo(({ children, onClick = () => null }) => {
  const [t] = useTranslation();

  const family = useFamilyData();

  const [familyId, setFamilyId] = useState(family?.[0]?.id);

  const { isAuthorized } = useAuth();

  const [showProfile, toggle] = useToggle(false);

  const chooseButton = useCallback(
    ({ familyProfileId }) => (
      <div className="modal-card__button-block">
        <CardButton
          className="primary-button modal-card__button"
          title={t("choose")}
          onClick={() => {
            toggle();
            setFamilyId(familyProfileId);
            onClick(familyProfileId);
          }}
        />
      </div>
    ),
    [onClick]
  );

  const mutatedClick = useCallback(() => {
    if (isAuthorized && family.length > 1) return toggle();

    onClick(familyId);
  }, [onClick, family, familyId, isAuthorized]);

  return (
    <>
      {children ? children(mutatedClick) : <CardButton className="primary-button" title={t("change_profile")} onClick={toggle} />}
      {showProfile && (
        <ChooseProfileBlock
          buttonChild={chooseButton}
          onClose={toggle}
          allProfiles={family}
          familyProfileId={familyId}
          mainTitle={t("select_the_profile")}
        />
      )}
      <style jsx global>{`
        @import "../Payment/PaymentBlock.scss?3";
        @import "../../RulesContent/RulesContent.scss?1";
      `}</style>
    </>
  );
});
