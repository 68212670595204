import React, { useState, useMemo } from "react";

import { HocWithPortal } from "../../../../../../hocs/hocWithPortal";
import { AlertBlock } from "../../../../../common/AlertBlock";
import DropBlock from "../../../../../common/DropBlock/DropBlock";
import { useTranslation } from "next-i18next";

function ChooseProfileBlock({
  onClose,
  buttonChild,
  allProfiles,
  mainTitle,
  familyProfileId,
}) {
  const {
    i18n: { language },
  } = useTranslation();
  const dropItems = useMemo(
    () =>
      allProfiles.map(({ id, firstName, lastName }) => ({
        id,
        title:
          language === "en"
            ? `${firstName} ${lastName}`
            : `${lastName} ${firstName}`,
      })),
    [allProfiles, language]
  );

  const [activeId, setActiveId] = useState(
    () =>
      allProfiles.find(({ id }) => +id === +familyProfileId)?.id ||
      allProfiles[0].id
  );

  return (
    <AlertBlock onClose={onClose}>
      <>
        <h2 className="sign-up-section__title">{mainTitle}</h2>

        <DropBlock
          activeId={activeId}
          dropItems={dropItems}
          changeActiveId={setActiveId}
        />

        {buttonChild({
          clas: "sign-up-section__reg-button",
          familyProfileId: activeId,
          finishFunc: onClose,
        })}
      </>
    </AlertBlock>
  );
}

export default HocWithPortal(ChooseProfileBlock);
