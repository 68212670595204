import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";

import Link from "next/link";
import { useRouter } from "next/router";

import {
  trainingNameHandler,
  // BUY_BUTTON_TITLES,
  freezeNameHandler,
} from "./languageConstants";

import { DATE_TITLE } from "../SubscriptionPageContent/languageConstants";
import { ChooseProfileSection } from "../TrainingSection/TrainingPage/ChooseProfileSection";

import { useTranslation } from "next-i18next";
import { GET_CLUB_HEADER_DATA } from "../../../../../graphql/queries/queriesClub";
import { useAuth } from "../../../../../utils/auth";
import { setAuthorization } from "../../../../../utils/redux/actionCreators/actions";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

function SubscriptionCard({
  id,
  title,
  subtitle,
  priceInfo,
  amount = 0,
  pauses = 0,
  duration,
  status,
  group,
  isGroup,
  templateId,

  // CustomButton,
  // customButtonProps,
  // isSubscription,
  // cardOpenPriceEnabled,
}) {
  const { price } = priceInfo || {};
  const router = useRouter();
  const {
    query: { club },
  } = router;

  const [t] = useTranslation();

  const { data: { club: { currencyTitle } } = {} } = useQuery(GET_CLUB_HEADER_DATA, { variables: { showOffer: false } });
  const { isAuthorized } = useAuth();

  const dispatch = useDispatch();

  const { dayTitle, countTitle, trainingTitle, countFreezeTitle, freezeName, buttonTitle, unlimitedTitle } = useMemo(() => {
    const trainingTitle = trainingNameHandler(amount),
      freezeName = freezeNameHandler(pauses),
      buttonTitle = "buy", // BUY_BUTTON_TITLES[status],
      dayTitle = DATE_TITLE(duration);

    return {
      dayTitle,
      countTitle,
      trainingTitle,
      buttonTitle, //: buttonTitle && "buy",
      unlimitedTitle,
      countFreezeTitle,
      freezeName,
    };
  }, [duration, amount, pauses, status]);

  const toDetail = useCallback(
    (familyProfileId) => {
      const pathname = `/club/${club}/subscription/${id}`;
      if (!isAuthorized) return dispatch(setAuthorization({ nextUrl: pathname }));

      return router.push(
        {
          pathname,
          query: { familyProfileId },
        },
        pathname
      );
    },
    [id, isAuthorized]
  );

  return (
    <Link
      href={`/club/${club}/subscription/${templateId ?? id}`}
      className="subscription-card"
      onClick={(e) => {
        if (e.target.nodeName === "BUTTON" || !e.currentTarget.contains(e.target)) e.preventDefault();
      }}
    >
      <div className="subscription-card-name">
        <span className="subscription-card-name-title">{title}</span>
        {subtitle && <div className="subscription-card-name-subtitle">{subtitle}</div>}
      </div>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {isGroup && (
          <div className="subscription-card-content-block" style={{ marginTop: "-10px" }}>
            <p className="subscription-card-content-title">{group}</p>
          </div>
        )}

        <div className="subscription-card-content-block">
          <p className="subscription-card-content-subtitle">{t("validity")}</p>
          <p className={`subscription-card-content-title ${duration ? "" : "subscription-card-content-title__hidden"}`}>
            {`${duration ? `${duration} ${t(dayTitle)}` : "&nbsp;"}`}
          </p>
        </div>

        <div className="subscription-card-content-block">
          <p className="subscription-card-content-subtitle">{t("amount")}</p>
          <p className="subscription-card-content-title">{amount === -1 ? t("unlimited") : `${amount} ${t(trainingTitle)}`}</p>
        </div>
        {!!pauses && (
          <div className="subscription-card-content-block">
            <p className="subscription-card-content-subtitle">{t("number_pauses")}</p>
            <p className={`subscription-card-content-title ${pauses ? "" : "subscription-card-content-title__hidden"}`}>
              {pauses} {t(freezeName)}
            </p>
          </div>
        )}
      </div>
      <hr />
      {/* {cardOpenPriceEnabled && ( */}
      {price && (
        <div className="subscription-card-price">
          {price} {currencyTitle}
        </div>
      )}
      <div className="subscription-card-button-wrapper">
        <ChooseProfileSection onClick={toDetail}>
          {(onClick) => (
            <button className={`subscription-card-button subscription-card-button_${status}`} onClick={onClick}>
              {t(buttonTitle)}
            </button>
          )}
        </ChooseProfileSection>
      </div>
    </Link>
  );
}

SubscriptionCard.propTypes = {
  id: PropTypes.number,
  templateId: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  amount: PropTypes.number,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStatus: PropTypes.bool,
  status: PropTypes.string,
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isGroup: PropTypes.bool,
  buttonFunc: PropTypes.func,
  activeClub: PropTypes.string,
  customButtonProps: PropTypes.object,
  // isSubscription: PropTypes.bool,
};

SubscriptionCard.defaultProps = {
  id: null,
  templateId: null,
  title: "",
  subtitle: "",
  amount: null,
  duration: null,
  isStatus: false,
  status: "buy",
  group: "",
  isGroup: false,
  buttonFunc: null,
  CustomButton: null,
  activeClub: "",
  customButtonProps: {},
  // isSubscription: false,
};
// status=default||activated||notActivated||expired||freeze||activeCanFreeze||request

export default SubscriptionCard;
