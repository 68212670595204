import React, { useMemo, useCallback, memo, useRef } from "react";
import PropTypes from "prop-types";

import Swiper from "react-id-swiper";

import SubscriptionCard from "./SubscriptionCard";

import {
  SLIDER_NEXT_BUTTON_SVG,
  SLIDER_PREV_BUTTON_SVG,
} from "../../../../../constants/svgConstants";
import clsx from "clsx";
import { useShowByMedia } from "../../../../../hooks/useShowByMedia";

const params = {
  slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 15,
  grabCursor: true,
  loop: true,
  speed: 700,

  scrollbar: {
    el: ".subscription-cards-swiper-scrollbar",
    hide: false,
    draggable: true,
  },

  breakpoints: {
    1100: {
      slidesPerView: 4,
      centeredSlides: false,
    },
    810: {
      slidesPerView: 3,
      centeredSlides: false,
    },
    500: {
      slidesPerView: 2,
      centeredSlides: false,
    },
  },
};

const arrowsParams = {
  scrollbar: {},

  navigation: {
    nextEl: ".sub-block-swiper-next-btn",
    prevEl: ".sub-block-swiper-prev-btn",
  },
};

const SubscriptionCardsBlock = memo(
  ({
    allCards,
    isArrows = false,
    newParams = { loop: false },
    cardOpenPriceEnabled,
  }) => {
    const swiperEl = useRef(null);
    const goNext = useCallback(() => {
      const swiper = swiperEl?.current?.swiper;
      if (swiper) swiper.slideNext();
    }, []);

    const goPrev = useCallback(() => {
      const swiper = swiperEl?.current?.swiper;
      if (swiper) swiper.slidePrev();
    }, []);

    const swiperParams = useMemo(
      () => (isArrows ? { ...params, ...arrowsParams } : params),
      [isArrows]
    );

    const showArrows = useShowByMedia(allCards.length);

    return (
      <div
        className={`subscription-cards-block ${
          isArrows ? "subscription-cards-arrows-block" : ""
        }`}
      >
        <div
          className={clsx("container", {
            "subscription-card-swiper-wrapper": !isArrows,
          })}
        >
          {showArrows &&
            (isArrows ? (
              <div className="subscription-card-arrows-block">
                <div
                  className="sub-block-swiper-prev-btn btn-arrow club-nav-btn-arrow"
                  onClick={goPrev}
                >
                  {SLIDER_PREV_BUTTON_SVG()}
                </div>
                <div
                  className="sub-block-swiper-next-btn btn-arrow club-nav-btn-arrow"
                  onClick={goNext}
                >
                  {SLIDER_NEXT_BUTTON_SVG()}
                </div>
              </div>
            ) : (
              <>
                <div
                  className="swiper-btn-arrow-prev btn-arrow"
                  onClick={goPrev}
                >
                  {SLIDER_PREV_BUTTON_SVG()}
                </div>
                <div
                  className="swiper-btn-arrow-next btn-arrow"
                  onClick={goNext}
                >
                  {SLIDER_NEXT_BUTTON_SVG()}
                </div>
              </>
            ))}

          <Swiper
            {...{ ...swiperParams, ...newParams }}
            shouldSwiperUpdate={true}
            renderScrollbar={() => (
              <div className="subscription-cards-swiper-scrollbar"></div>
            )}
            ref={swiperEl}
          >
            {allCards.map(({ id, ...props }) => {
              return (
                <div key={id}>
                  <SubscriptionCard
                    {...{
                      ...props,
                      id: +id,
                      // isSubscription: isArrows,
                      cardOpenPriceEnabled,
                    }}
                  />
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  }
);

SubscriptionCardsBlock.propTypes = {
  allCards: PropTypes.array,
  isArrows: PropTypes.bool,
  buttonFunc: PropTypes.func,
  // newParams: PropTypes.object,
};

SubscriptionCardsBlock.defaultProps = {
  allCards: [],
  isArrows: false,
  buttonFunc: null,
  // newParams: {},
};

export default SubscriptionCardsBlock;
